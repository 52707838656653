import * as React from "react";
import { Link } from "gatsby";
import { Layout, PageTitle } from "../../components";
import Seo from "../../components/Seo";

const ContactSuccessPage = () => {
  return (
    <Layout>
      <Seo title="CONTACT - お問い合わせ" />
      <PageTitle title="FORM SUBMITTED" subTitle="お問い合わせ完了" />
      <p className="mb-16">
        この度はお問い合わせメールをお送りいただきありがとうございます。後ほど、担当者よりご連絡をさせていただきます。
        <br />
        今しばらくお待ちくださいますようよろしくお願い申し上げます。
        <br />
        <br />
        なお、しばらくたってもemo TOKYOより返信、返答がない場合は、
        <br />
        お客様によりご入力いただいたメールアドレスに誤りがある場合がございます。
        <br />
        その際は、お手数ですが再度送信いただくか、お電話にてご連絡いただけますと幸いです。
      </p>
      <Link
        to="/"
        className="block w-full lg:w-80 mx-auto font-medium text-xs text-center tracking-widest py-4 lg:py-6 border border-primary-purple hover:bg-primary-purple hover:text-white transform duration-300"
      >
        HOME
      </Link>
    </Layout>
  );
};

export default ContactSuccessPage;
